import logo from './logo.svg';import './App.css';
import Navbar from './component/Navbar';
import Header from './component/Header';
import Produk from './component/Produk';
import Video from './component/Video'
import Galeri from './component/Galeri';
import Footer from './component/Footer';
import Lokasi from './component/Lokasi';
import Testimoni from './component/Testimoni';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Video/>
      <Produk/>
      <Galeri/>
      {/* <Testimoni/> */}
      <Lokasi/>
      <Footer/>
    </div>
  );
}

export default App;

import React from "react";

import backgroudImg from "../assets/img/header.jpg"
import Logo from "../assets/img/logo.png"

import "../assets/style.css"

const style = {
    backgroundColor: "hsla(0, 0%, 0%, 0.6)",
    height: "34em"
}

const backgroundStyle = {
    backgroundImage:`url(${backgroudImg})`, 
    height: '34em', 
    backgroundPosition: 'center'
}

const Header = () => {
    return (
        <div class="bg-image"
            style={backgroundStyle}
            id="header">
            <div class="mask" style={style}>
                <div class="Jumbotron jumbotron-fluid text-white">
                    <div class="container p-5">
                        <img class="logo" src={Logo}/>
                        <p class="h1 display-4" style={{marginTop: '0.2em', fontFamily: 'raleway'}}>CV. Sumber Rejeki</p>
                        <p class="lead pb-5" style={{fontFamily:'barlow'}}>Distributor Pupuk Pertanian dan Perkebunan</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Header;
import React from "react";

import ProdukDesktop from "./ProdukDesktop";
import ProdukMobile from "./ProdukMobile"
import { useMediaQuery } from "react-responsive";

import "../assets/style.css"

const Produk = () => {

    const isDesktop = useMediaQuery({query: '(min-width: 760px)'})
    const isMobile = useMediaQuery({query: '(max-width: 760px)'})

    return(
        <div class='cnt' id="produk">
            {isDesktop && <ProdukDesktop/>}
            {isMobile && <ProdukMobile/>}
        </div>
    )
}

export default Produk
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css/bundle"

import pupukNPK from "../assets/img/NPK 1.jpg"
import pupukNPK2 from "../assets/img/NPK 2.jpg"
import pupukFosfat from "../assets/img/Fosfat alam.jpg"
import pupukSP36 from "../assets/img/SP 36.jpg"

import '../assets/style.css'

const rowClass = 'row mt-5 mb-4'

const tableClass = 'table table-striped text-start'

const Img = (props) => {
    return (
        <img src={props.img} class="rounded-4 shadow imgProduk" alt=""/>
    )
}

const Table = (props) => {
    return (
         <tr>
            <th scope="col" class="align-top p-2" style={{width: '16%', fontFamily: 'nunito sans'}}>
                {props.type}
            </th>
            <th scope="col" class="align-top p-2" style={{fontFamily: 'nunito sans'}}>
                :
            </th>
            <th scope="col" class="p-2" style={{fontFamily: 'raleway'}}>
                {props.desc}
            </th>
        </tr>
    )
}

const Produk = () => {
    return(
        <div class='container pb-5'>
            <p class="h3 m-4 pt-3" style={{fontFamily: 'poppins'}}>Produk Kami</p>
            <div class={rowClass}>
                <div class='col-md-4 text-center'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                        modules={[Autoplay]}
                        className="mySwiper"
                        loop={true}
                    >
                        <SwiperSlide><Img img={pupukNPK} /></SwiperSlide>
                        <SwiperSlide><Img img={pupukNPK2} /></SwiperSlide>
                    </Swiper>
                </div>
                <div class='col'  id="col">
                    <table class={tableClass}>
                        <Table type="Jenis" desc="Pupuk NPK 15-15-15 Padat"/>
                        <Table type="Merk Dagang" desc="SRPONSKAH"/>
                        <Table type="Nomor SNI" desc="2803 : 2012"/>
                        <Table type="Produsen" desc="CV. SUMBER REJEKI - Sidoarjo"/>
                        <Table type="Komposisi" desc="Nitrogen 15%, Fosfat 15%, Kalium 15%, MGO, CAO"/>
                        <Table type="Warna" desc="Merah"/>
                        <Table type="Bentuk" desc="Granul (Butiran)"/>
                        <Table type="Kemasan" desc="50KG"/>
                        <Table type="Kegunaan" desc="Meningkatkan hasil panen, Membuat tanaman lebih hijau dan segar, Meningkatan daya tahan terhadap serangan hama penyakit dan kekeringan, Meningkatkan mutu benih dan bibit"/>
                    </table>
                    
                </div>
            </div>
            <div class={rowClass}>
                <div class='col-md-4 text-center'>
                    <Img img={pupukFosfat} />
                </div>
                <div class='col' id="col">
                    <table class={tableClass}>
                        <Table type="Jenis" desc="Pupuk Fosfat Alam"/>
                        <Table type="Merk Dagang" desc="Kepala Gudel"/>
                        <Table type="Nomor SNI" desc="02-3766-2005"/>
                        <Table type="Produsen" desc="CV. SUMBER REJEKI - Sidoarjo"/>
                        <Table type="Komposisi" desc="Fosfat min. 22%, Larutan Asam Sulfat 2% min. 10%, Kadar Air min. 5%"/>
                        <Table type="Warna" desc="Abu-abu"/>
                        <Table type="Bentuk" desc="Granul (Butiran)"/>
                        <Table type="Kemasan" desc="50KG"/>
                        <Table type="Kegunaan" desc="Memperbaiki unsur hara, Merangsang pertumbuhan tanaman, Memperbaiki sifat fisik tanah, Mempercepat pertumbuhan akar yang sehat dengan mengambil unsur hara di dalam tanah sehingga menjadikan tanaman lebih kuat"/>
                    </table>
                </div>
            </div>
            <div class={rowClass}>
                <div class='col-md-4 text-center'>
                    <Img img={pupukSP36} />
                </div>
                <div class='col' id="col">
                    <table class={tableClass}>
                        <Table type="Jenis" desc="Pupuk Fosfat Alam SP-36"/>
                        <Table type="Merk Dagang" desc="Kepala Gudel"/>
                        <Table type="Nomor SNI" desc="02-3766-2005"/>
                        <Table type="Produsen" desc="CV. SUMBER REJEKI - Mojokerto"/>
                        <Table type="Komposisi" desc="Fosfat: ± 22%, Sulfur ± 2,86%"/>
                        <Table type="Warna" desc="Abu-abu"/>
                        <Table type="Bentuk" desc="Granul (Butiran)"/>
                        <Table type="Kemasan" desc="50KG"/>
                        <Table type="Kegunaan" desc="Memicu pertumbuhan akar, Menghijaukan daun, Memperbaiki warna, aroma, rasa, dan besarnya buah dan umbi dihasilkan, Meningkatkan ketahanan hasil panen"/>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Produk
import React from "react";
import {HashLink as Link} from "react-router-hash-link"
import $ from "jquery";

import "../assets/style.css"

$(window).scroll(function() {
    if ($(window).scrollTop() >= 50) {
        $('.navbar').addClass('bg-body-tertiary ')
        $('.navbar-brand').removeClass('text-white')
        $('.nav-link').removeClass('text-white')
        $('.navbar').removeClass('navbar-dark')
    } else {
        if($(window).innerWidth() <= 760) {
            $('.navbar-brand').removeClass('text-white')
            $('.nav-link').removeClass('text-white')
        } else {
            $('.navbar-brand').addClass('text-white')
            $('.nav-link').addClass('text-white')
        }
        $('.navbar').removeClass('bg-body-tertiary ')
        $('.navbar').addClass('navbar-dark')
    }
    
})

$(document).ready(function(){

    if($(window).innerWidth() <= 760) {
        $('.navbar-brand').removeClass('text-white')
        $('.nav-link').removeClass('text-white')
    } else {
        $('.navbar-brand').addClass('text-white')
        $('.nav-link').addClass('text-white')
    }

    $(document).click(function(event){
        var clickover = $(event.target);
        var _opened = $(".offcanvas").hasClass("show")
        if(_opened === true && clickover.hasClass("nav-link")) {
            $("button.navbar-toggler").click()
        }
    })
})


export default function Navbar() {
    return (
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-dark fixed-top shadow" style={{transition: "all 0.5s"}}>
                <div class="container">
                    <Link class="navbar-brand text-white" >CV. Sumber Rejeki</Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas" aria-controls="#navbarOffcanvas" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="offcanvas offcanvas-end" id="navbarOffcanvas">
                        <div class="offcanvas-header float-end">
                            <button type="button" class="btn-close mt-1" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <ul class="navbar-nav ms-auto justify-content-end" >
                                <li class="nav-item">
                                    <Link class="nav-link text-white" to="#header">Home</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link text-white" to='#video'>Tentang Kami</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link text-white" to="#produk">Produk</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link text-white" to="#galeri">Galeri</Link>
                                </li>
                                {/* <li class="nav-item">
                                    <Link class="nav-link text-white" to="#testimoni">Testimoni</Link>
                                </li> */}
                                <li class="nav-item">
                                    <Link class="nav-link text-white" to="#lokasi">Lokasi</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </nav>
        </div>
    )
}

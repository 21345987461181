import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/bundle"


import "../assets/style.css"

import Abu from "../assets/img/ABU GALERI.png"
import Merah from "../assets/img/MERAH GALERI.png"
import IsoGaleri from "../assets/img/ISO GALERI.jpg"
import PonskahGaleri from "../assets/img/PONSKAHH GALERI.jpg"
import AnjuranPakai from "../assets/img/Anjuran pakai galeri.png"


const classImg = "galeri img-fluid img-thumbnail rounded-4 p-2 shadow-sm";

function Galeri() {
    return (
        <div class="bg-body-secondary">
            <div class="container pb-5 cnt" id="galeri">
                <p class="h3 m-4" style={{fontFamily: 'poppins'}}>Galeri</p>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={20}
                    pagination={{
                        dynamicBullets: true
                    }}    
                    // navigation={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    breakpoints= {{
                        360: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        }
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"     
                    style={{margin: 10, paddingBottom: 30, 
                        "--swiper-pagination-color": "seagreen",}}
                >
                    <SwiperSlide>
                        <Link to={Merah} target="_blank">
                            <img class={classImg} src={Merah} alt=""/>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to={Abu} target="_blank">
                            <img class={classImg} src={Abu} alt=""/>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to={AnjuranPakai} target="_blank">
                            <img class={classImg} src={AnjuranPakai} alt=""/>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to={IsoGaleri} target="_blank">
                            <img class={classImg} src={IsoGaleri} alt=""/>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to={PonskahGaleri} target="_blank">
                            <img class={classImg} src={PonskahGaleri} alt=""/>
                        </Link>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Galeri
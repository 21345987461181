import React from "react";

import { Link } from "react-router-dom";

import "../assets/style.css"

 const Video = () => {
    return (
        <div class='bg-body-secondary'>
            <div class="container pt-5 pb-5" id="video">
                <div class="row pt-5">
                    <div class="col-md-6" style={{textAlign: 'justify'}}>
                        <p class="h3 mt-4" style={{fontFamily: 'poppins'}}>Tentang Kami</p>
                        <div class='p-1' style={{fontFamily: 'nunito sans'}}>
                            <p>Kami adalah distributor resmi pupuk an-organik NPK padat dengan merk dagang terdaftar MAKOTA TANI MAKMUR & SRPONSKAH serta pupuk Fosfat Alam (Butiran) dengan merk dagang KEPALA GUDEL ESPE 36</p>
                            <p>Menyediakan produk nutrisi tanaman yang kompetitif dan solusi pertanian yang berkelanjutan serta mampu memberikan dampak ekonomi dan sosial secara nasional melalu ekosistem pertanian di seluruh wilayah Indonesia</p>
                        </div>
                        <Link to="https://drive.google.com/file/d/1ibHvmBz_A6U7xRocbOX_ti2wg-LMrXhJ/view?usp=drive_link" class="btn btn-secondary mt-1 mb-1" target="_blank">
                            Company Profile
                        </Link>
                    </div>
                    <div class="col-md-5 ratio ratio-16x9 mb-4 p-2 shadow rounded-4" id="video-frame">
                        <iframe 
                            id="video-assets"
                            title="video"
                            class="rounded-4"
                            src="https://www.youtube.com/embed/0pjUxdnkafk"
                            allowfullscreen
                            >
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        
    )
 }

 export default Video
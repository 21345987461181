import React from "react";
import { Link } from "react-router-dom";

import "../assets/style.css"

const Lokasi = () => {
    return(
        <div class="container pb-4 mt-4 cnt" id="lokasi">
            {/* <div>
                <p class="h3 m-4"><u>Lokasi</u></p>
            </div> */}
            <div class="row justify-content-center">
                <div class="col-md-5 text-start">
                    <p class="h6">Kunjungi kami di</p>
                    <p class="h5" style={{fontFamily: 'nunito sans'}}>Jl. Kludan RT. 04, RW 02 Kec. Tanggulangin, Kab. Sidoarjo, Jawa Timur, Indonesia</p>
                    <Link to="https://maps.app.goo.gl/yXpkU3ACjmYT4E5s6" target="_blank" class="btn btn-success mt-1">
                        Lihat Rute
                    </Link>
                </div>
                <div class="co-md-5 col-sm-6 ratio ratio-4x3" id="map-frame">
                    <iframe 
                        class="rounded-4 shadow"
                        id="map"
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.6571182351245!2d112.7050654!3d-7.503052799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7e151a9ebe6bd%3A0x949138475b6c263!2sRuko%20permata%20R1%2F21!5e0!3m2!1sid!2sid!4v1702541251663!5m2!1sid!2sid" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            
        </div>
    )
}

export default Lokasi